module.exports = {
  robokassa: {
    1: 'https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=betterlife_school&InvId=0&Culture=ru&Encoding=utf-8&Description=%D0%9A%D1%83%D1%80%D1%81%20%D0%B4%D0%BB%D1%8F%20%D0%BD%D0%BE%D0%B2%D0%B8%D1%87%D0%BA%D0%BE%D0%B2%20+%20%D0%91%D0%BE%D0%BD%D1%83%D1%81%2022%20%D1%8D%D0%BD%D0%B5%D1%80%D0%B3%D0%B8%D0%B8&OutSum=9900&SignatureValue=56697e692eecc0ea70fb88e00eca6d43',
    4: 'https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=betterlife_school&InvId=0&Culture=ru&Encoding=utf-8&Description=%D0%9A%D1%83%D1%80%D1%81%20%D0%B4%D0%BB%D1%8F%20%D0%BF%D1%80%D0%BE%D0%B2%D0%BE%D0%B4%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2%20+%20%D0%91%D0%BE%D0%BD%D1%83%D1%81%2022%20%D1%8D%D0%BD%D0%B5%D1%80%D0%B3%D0%B8%D0%B8&OutSum=14000&SignatureValue=9ba9178ac236ae2a7a64df39d10f3721',
    2: 'https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=betterlife_school&InvId=0&Culture=ru&Encoding=utf-8&Description=%D0%9A%D1%83%D1%80%D1%81%20%D0%B4%D0%BB%D1%8F%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B2%D0%B8%D0%BD%D1%83%D1%82%D1%8B%D1%85%20+%20%D0%9C%D0%9A%20%22%D0%9C%D0%BE%D0%BB%D0%B2%D0%B8%D0%BD%D0%B5%D1%86%22&OutSum=19900&SignatureValue=c27b58f40fbe0794e1bb73bec0b6b273'
  },
  robokassa_class: {
    1: 'https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=betterlife_school&InvId=0&Culture=ru&Encoding=utf-8&Description=%D0%91%D0%BE%D0%BB%D1%8C%20%D0%B1%D0%BB%D0%BE%D0%B3%D0%B5%D1%80%D0%B0%20+%20%D0%91%D0%BE%D0%BD%D1%83%D1%81%20%D0%A1%D0%B0%D0%BC%D0%BE%D1%86%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D1%8C&OutSum=1390&SignatureValue=3c7c11123b223af82f28a4236fc34eec'
  },
  robokassa_lila: {
    1: 'https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=betterlife_school&InvId=0&Culture=ru&Encoding=utf-8&Description=%D0%9B%D0%B8%D0%BB%D0%B0%20%D1%87%D0%B0%D0%BA%D1%80%D0%B0%20%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B8%20%D1%81%D0%B5%D1%80%D1%82%D0%B8%D1%84%D0%B8%D0%BA%D0%B0%D1%86%D0%B8%D1%8F&OutSum=9700&SignatureValue=05e07ec38f78edf194834c33d0d2f528'
  },
  googleDocs: {
    books: {
      1: 'https://drive.google.com/file/d/1KI-Bh1MPut01j3dK8NXEYd4-SbHEMbqs/view?usp=drivesdk',
      2: 'https://drive.google.com/file/d/1PI3isQVY3ET1KDUFTeRNLnGXmQO6bnnw/view?usp=drivesdk',
      4: 'https://drive.google.com/file/d/1KI-Bh1MPut01j3dK8NXEYd4-SbHEMbqs/view?usp=drivesdk'
    },
    presentation: {
      1: 'https://drive.google.com/file/d/1v2K4F94ZPq4HXLBAkZTOXsktROCfD53_/view?usp=sharing',
      2: 'https://drive.google.com/file/d/1BNkdsvnrzI3cmSUzVNygU8Sttcn9HN9Z/view?usp=sharing',
      4: 'https://drive.google.com/file/d/1NWPBPjQ-rZtuC7hqb9FUHL2HEo9mImnF/view?usp=sharing'
    },
    presentation_class: {
      1: 'https://drive.google.com/file/d/12SnmV5xM2nraEGL6TbiFujJkCNjCNApB/view?usp=drivesdk'
    },
    presentation_lila: {
      1: 'https://drive.google.com/file/d/10H3IUDu3XQa7kxcN7Z1Uz-dU59gnDCUm/view?usp=drivesdk',
    },
    bonus: {
      2: 'https://drive.google.com/file/d/1PTU8bwzKoIvRDkK6MhXm6gbsGSJIsoFD/view?usp=sharing',
      4: 'https://drive.google.com/file/d/1UDbsDCEx-HLbsuD2jPzn6PHn-HHGsdFm/view?usp=sharing'
    },
    gift: {
      1: 'https://drive.google.com/file/d/1bWg4SSNiRhzfE_-SAlevoPLDzB1REn4o/view?usp=drivesdk',
      2: 'https://drive.google.com/file/d/1bWg4SSNiRhzfE_-SAlevoPLDzB1REn4o/view?usp=drivesdk',
      4: 'https://drive.google.com/file/d/1bWg4SSNiRhzfE_-SAlevoPLDzB1REn4o/view?usp=drivesdk',
    },
    file: {
      1: 'https://drive.google.com/file/d/1g6UalJSQ2_UexJ_EXVB1KybOi1UdvQ9_/view?usp=drivesdk',
    },
    file2: {
      1: 'https://docs.google.com/file/d/1S96Giyl36YoWJ19y6UH37rWASaxuUqAR/edit?usp=docslist_api&filetype=msword'
    }
  }
}